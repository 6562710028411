<template>
  <section class="mt-4 md:mt-8 lg:mt-12">
    <Container>
      <h2 class="text-center text-3xl font-bold tracking-tight sm:text-4xl">
        News
      </h2>
    </Container>

    <BlogCategories v-if="block.show_categories" />
    
    <Container>
      <div
      class="mx-auto mt-4 md:mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4"
      v-if="articles"
      >
        <BlogCard v-for="article in articles" :data="article" />
      </div>

      <div class="text-center my-8">
        <NuxtLink class="nba underline text-xl" to="/blog">More news</NuxtLink>
      </div>
    </Container>
  </section>
</template>

<script setup>
const supabase = useSupabaseClient()
const config = useRuntimeConfig()
const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})
const block = props.content

const { data: articles } = await useAsyncData(
  'lp-block-articles-'+block.no_articles, 
  async () => {
    const { data } = await supabase
    .from('articles')
    .select(`
      *,
      image(
        id,
        filename_download,
        description,
        width,
        height
      ),
      articles_author(
        author(*)
      ),
      articles_article_categories(
        article_categories(*)
      )
    `)
    .limit(block.no_articles)
    .in('status', config.public.postStatus.split(','))
    return data
  }
)

</script>